import { absolutePath } from "./absolutePath";

interface IndexParams {
  formId: number;
  params: {
    page: number;
    per_page: number;
  };
}

export const submissions = {
  create: ({ formId }: { formId: number }) =>
    absolutePath(`forms/${formId}/submissions`),
  index: ({ formId, params }: IndexParams) =>
    absolutePath(`forms/${formId}/submissions`, params),
};
